import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckBoxOne from '../../components/CheckBoxOne';
import CheckBoxTwo from '../../components/CheckBoxTwo';
import CheckBoxThree from '../../components/CheckBoxThree';
import CheckBoxFour from 'components/CheckBoxFour';
import IndustrySelect from '../../components/IndustrySelect';
import CheckBoxFive from 'components/CheckBoxFive';
import CheckBoxSix from 'components/CheckBoxSix';
import CheckBoxSeven from 'components/CheckBoxSeven';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import CheckBoxOneEn from 'components/CheckBoxOneEn';
import CheckBoxTwoEn from 'components/CheckBoxTwoEn';
import CheckBoxThreeEn from 'components/CheckBoxThreeEn';
import CheckBoxFourEn from 'components/CheckBoxFourEn';
import CheckBoxFiveEn from 'components/CheckBoxFiveEn';
import CheckBoxSixEn from 'components/CheckBoxSixEn';
import CheckBoxSevenEn from 'components/CheckBoxSevenEn';
import CheckBoxEight from 'components/CheckBoxEight';
import CheckBoxEightEn from 'components/CheckBoxEightEn';
import CheckBoxNine from 'components/CheckBoxNine';
import CheckBoxNineEn from 'components/CheckBoxNineEn';
import CheckBoxTen from 'components/CheckBoxTen';
import CheckBoxTenEn from 'components/CheckBoxTenEn';
import CheckBoxEleven from 'components/CheckBoxEleven';
import CheckBoxElevenEn from 'components/CheckBoxElevenEn';
import CheckBoxTwelve from 'components/CheckBoxTwelve';
import CheckBoxTwelveEn from 'components/CheckBoxTwelveEn';
import CheckBoxThirteen from 'components/CheckBoxThirteen';
import CheckBoxThirteenEn from 'components/CheckBoxThirteenEn';

export default function Stepper2() {
  const { isDeutsch } = useLanguage();
  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom align='center' style={{ marginBottom: '20px' }}>
        {isDeutsch
          ? translationFunction().deutschTranslations.stepper2
          : translationFunction().englishTranslations.stepper2}
      </Typography>
      <Typography variant='h6' gutterBottom>
        {isDeutsch ? 'Erforderliche Angaben' : 'Mandatory information'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxOne /> : <CheckBoxOneEn />}
        </Grid>
        {/*  <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxTwo /> : <CheckBoxTwoEn />}
        </Grid> */}
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxThree /> : <CheckBoxThreeEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          <IndustrySelect />
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxEleven /> : <CheckBoxElevenEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxTwelve /> : <CheckBoxTwelveEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxThirteen /> : <CheckBoxThirteenEn />}
        </Grid>
      </Grid>
      <br />
      <Typography variant='h6' gutterBottom>
        {isDeutsch ? 'Freiwillige Angaben' : 'Voluntary information'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxFour /> : <CheckBoxFourEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxSix /> : <CheckBoxSixEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxSeven /> : <CheckBoxSevenEn />}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxEight /> : <CheckBoxEightEn />}
        </Grid>
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxNine /> : <CheckBoxNineEn />}
        </Grid> */}
        <Grid item xs={12} md={6}>
          {isDeutsch ? <CheckBoxTen /> : <CheckBoxTenEn />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
