// UserContext.tsx
import axios from 'axios';
import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';

interface User {
  id: number;
  email: string;
  companyName: string;
  companyType: string;
  companyZipcode: any;
  companyLocation: string;
  contactFirstname: string;
  contactLastname: string;
  industrySector: string[];
  selectedCountry: any;
  aufwandOne: string;
  aufwandTwo: string;
  companyItExpertsFrom: any;
  companyItExpertsTo: any;
  aufwandThree: string;
  aufwandFour: string[];
  aufwandFive: string[];
  aufwandSix: string;
  aufwandSeven: string[];
  aufwandEight: string;
  dataConnectionsFrom: any;
  dataConnectionsTo: any;
  aufwandNine: string;
  incomingDataFrom: any;
  incomingDataTo: any;
  aufwandTen: string;
  userAmountFrom: any;
  userAmountTo: any;
  aufwandEleven: string;
  cpu: any;
  aufwandTwelve: string;
  ram: any;
  aufwandThirteen: string;
  bandwidth: any;
}

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  syncUserSelections: (updatedUser: User) => Promise<void>;
}
const baseURL = process.env.REACT_APP_API_BASE_URL;
const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Check if user data is stored in localStorage and set it in context if available
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    // Store user data in localStorage whenever user state changes
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const syncUserSelections = async (updatedUser: User) => {
    if (user) {
      try {
        const response = await axios.put(`${baseURL}/api/users/${user.id}`, updatedUser, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error updating user selections:', error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, syncUserSelections }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
