import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import { Menu } from '@mui/material';

export default function CheckBoxElevenEn() {
  const { aufwandEleven, setAufwandEleven } = useMyContext();
  const { cpu, setCpu } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandEleven);

  const handleUpdate = async (value: any, cpu: number) => {
    try {
      const updates = {
        aufwandEleven: value,
        cpu: cpu,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandEleven(value); // Update local context/state immediately
    let cpu = 0;

    switch (value) {
      case '1':
        cpu = 1;
        break;
      case '2':
        cpu = 2;
        break;
    }

    setCpu(cpu);
    handleUpdate(value, cpu); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandEleven) {
      setAufwandEleven(selectedValue);
      setSelectedValue(aufwandEleven);
      setCpu(1);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Computing power (CPU)
        </FormLabel>
        <Tooltip
          title='Do programs need to do a lot of computing? E.g. simulations, AI or data analysis'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Choose an element</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Choose an element'
          onChange={handleChange}
        >
          <MenuItem value={'1'}>Standard CPU power</MenuItem>
          <MenuItem value={'2'}>High CPU power required</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
