import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import { Menu } from '@mui/material';

export default function CheckBoxTwelveEn() {
  const { aufwandTwelve, setAufwandTwelve } = useMyContext();
  const { ram, setRam } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandTwelve);

  const handleUpdate = async (value: any, ram: number) => {
    try {
      const updates = {
        aufwandTwelve: value,
        ram: ram,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandTwelve(value); // Update local context/state immediately
    let ram = 0;

    switch (value) {
      case '2':
        ram = 1;
        break;
      case '3':
        ram = 2;
        break;
      default:
        ram = 1;
    }

    setRam(ram);
    handleUpdate(value, ram); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandTwelve) {
      setAufwandTwelve(selectedValue);
      setSelectedValue(aufwandTwelve);
      setRam(2);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Memory (RAM)
        </FormLabel>
        <Tooltip
          title='Do you work with large or memory-intensive programs?'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Choose an element</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Choose an element'
          onChange={handleChange}
        >
          <MenuItem value={'2'}>Little RAM needed</MenuItem>
          <MenuItem value={'3'}>More RAM required</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
