import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useMyContext } from '../MyContext';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import loginUser from '../loginUser';
import { useLanguage } from '../LanguageContext';

const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
      height: 300,
    },
  },
};

/* -	Automobilhersteller und -zulieferer
-	Elektromobilität und Ladeinfrastruktur
-	Fahrzeug- und Flottenmanagement
-	Fahrzeugprüfung
-	Logistik
-	Mikromobilität und Sharing
-	Mobilitätspolitik
-	Öffentlicher Verkehr
-	Sensordaten und IoT
-	Smart City und urbane Mobilität
-	Telekommunikation
-	Tourismus und Freizeitmobilität
-	Verkehrsinfrastrukturplanung, -analyse und -management
-	Versicherungen (Non-Life)
-	Wetterdaten und -analyse für Mobilität
- Sonstige */

const datas = [
  { label: 'Automobilhersteller und -zulieferer', value: 'automanufacture' },
  { label: 'Elektromobilität und Ladeinfrastruktur', value: 'emobility' },
  { label: 'Fahrzeug- und Flottenmanagement', value: 'vehiclemanagement' },
  { label: 'Fahrzeugprüfung', value: 'vehicletesting' },
  { label: 'Logistik', value: 'logistics' },
  { label: 'Mikromobilität und Sharing', value: 'microshare' },
  { label: 'Mobilitätspolitik', value: 'mobilepolicy' },
  { label: 'Öffentlicher Verkehr', value: 'publictransport' },
  { label: 'Sensordaten und IoT', value: 'sensordata' },
  { label: 'Smart City und urbane Mobilität', value: 'smartcity' },
  { label: 'Telekommunikation', value: 'telecommunication' },
  { label: 'Tourismus und Freizeitmobilität', value: 'tourism' },
  { label: 'Verkehrsinfrastrukturplanung, -analyse und -management', value: 'transportplanning' },
  { label: 'Versicherungen (Non-Life)', value: 'insurance' },
  { label: 'Wetterdaten und -analyse für Mobilität', value: 'weather' },
  { label: 'Sonstige', value: 'others' },
];

/* -	Automotive manufacturers and suppliers
-	E-mobility and charging infrastructure
-	Vehicle and fleet management
-	Vehicle testing
-	Logistics
-	Micromobility and sharing
-	Mobility policy
-	Public transport
-	Sensor data and IoT
-	Smart city and urban mobility
-	Telecommunications
-	Tourism and leisure mobility
-	Traffic infrastructure planning, analysis and management
-	Insurance (non-life)
-	Weather data and analysis for mobility
-	Other */

const datasEn = [
  { label: 'Automotive manufacturers and suppliers', value: 'automanufacture' },
  { label: 'E-mobility and charging infrastructure', value: 'emobility' },
  { label: 'Vehicle and fleet management', value: 'vehiclemanagement' },
  { label: 'Vehicle testing', value: 'vehicletesting' },
  { label: 'Logistics', value: 'logistics' },
  { label: 'Micromobility and sharing', value: 'microshare' },
  { label: 'Mobility policy', value: 'mobilepolicy' },
  { label: 'Public transport', value: 'publictransport' },
  { label: 'Sensor data and IoT', value: 'sensordata' },
  { label: 'Smart city and urban mobility', value: 'smartcity' },
  { label: 'Telecommunications', value: 'telecommunication' },
  { label: 'Tourism and leisure mobility', value: 'tourism' },
  { label: 'Traffic infrastructure planning, analysis and management', value: 'transportplanning' },
  { label: 'Insurance (non-life)', value: 'insurance' },
  { label: 'Weather data and analysis for mobility', value: 'weather' },
  { label: 'Other', value: 'others' },
];

export default function IndustrySelect() {
  const { industrySector, setIndustrySector } = useMyContext();
  const { user, setUser, syncUserSelections } = useUser();
  const { isDeutsch } = useLanguage();

  const handleUpdate = async (value: any) => {
    try {
      const updates = { industrySector: value };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleLoginUser = async (value: any) => {
    try {
      const updates = { industrySector: value };
      const data = await loginUser(localStorage.getItem('jwt'), updates);
      localStorage.setItem('jwt', data.jwt);
      setUser(data.user);
    } catch (error) {
      console.error('Login/Registration failed:', error);
    }
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedValue = Array.isArray(value) ? value : [value];
    setIndustrySector(selectedValue);
    handleUpdate(selectedValue);
    handleLoginUser(selectedValue);
  };

  useEffect(() => {
    setIndustrySector(industrySector);
  }, [industrySector]);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          {isDeutsch ? 'Branche' : 'Field'}
        </FormLabel>
        <Tooltip
          title='In welcher Branche ist Ihre Unternehmen tätig?'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>
          {isDeutsch ? 'Mehrfachantwort möglich' : 'Multiple Choice possible'}
        </InputLabel>
        <Select
          labelId='element'
          id='someelement'
          multiple
          value={industrySector}
          label='Mehrfachantwort möglich'
          onChange={handleChange}
          input={
            <OutlinedInput
              label={isDeutsch ? 'Mehrfachantwort möglich' : 'Multiple Choice possible'}
            />
          }
          renderValue={(selected) =>
            selected
              .map((value) => {
                const selectedItem = (isDeutsch ? datas : datasEn).find(
                  (item) => item.value === value,
                );
                return selectedItem ? selectedItem.label : '';
              })
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          {(isDeutsch ? datas : datasEn).map((data, index) => (
            <MenuItem key={data.value} value={data.value} style={{ whiteSpace: 'normal' }}>
              <Checkbox
                checked={industrySector.includes(data.value)}
                style={{ marginLeft: '-10px' }}
              />
              <ListItemText primary={data.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
