// MyContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useUser } from './UserContext'; // Import the UserContext
import axios from 'axios';

interface MyContextType {
  aufwandOne: string;
  setAufwandOne: (value: string) => void;
  aufwandTwo: string;
  setAufwandTwo: (value: string) => void;
  aufwandThree: string;
  setAufwandThree: (value: string) => void;
  aufwandFour: string[];
  setAufwandFour: (values: string[]) => void;
  aufwandFive: string[];
  setAufwandFive: (value: string[]) => void;
  aufwandSix: string;
  setAufwandSix: (value: string) => void;
  aufwandSeven: string[];
  setAufwandSeven: (value: string[]) => void;
  aufwandEight: string;
  setAufwandEight: (value: string) => void;
  dataConnectionsFrom: number | undefined;
  setDataConnectionsFrom: (value: number | undefined) => void;
  dataConnectionsTo: number | undefined;
  setDataConnectionsTo: (value: number | undefined) => void;
  aufwandNine: string;
  setAufwandNine: (value: string) => void;
  incomingDataFrom: number | undefined;
  setIncomingDataFrom: (value: number | undefined) => void;
  incomingDataTo: number | undefined;
  setIncomingDataTo: (value: number | undefined) => void;
  aufwandTen: string;
  setAufwandTen: (value: string) => void;
  userAmountFrom: number | undefined;
  setUserAmountFrom: (value: number | undefined) => void;
  userAmountTo: number | undefined;
  setUserAmountTo: (value: number | undefined) => void;
  aufwandEleven: string;
  setAufwandEleven: (value: string) => void;
  cpu: number | undefined;
  setCpu: (value: number | undefined) => void;
  aufwandTwelve: string;
  setAufwandTwelve: (value: string) => void;
  ram: number | undefined;
  setRam: (value: number | undefined) => void;
  aufwandThirteen: string;
  setAufwandThirteen: (value: string) => void;
  bandwidth: number | undefined;
  setBandwidth: (value: number | undefined) => void;
  companyName: string;
  setCompanyName: (value: string) => void;
  industrySector: string[];
  setIndustrySector: (values: string[]) => void;
  companyType: string;
  setCompanyType: (value: string) => void;
  companyLocation: string;
  setCompanyLocation: (value: string) => void;
  companyZipcode: number | undefined;
  setCompanyZipcode: (value: number | undefined) => void;
  selectedCountry: any;
  setSelectedCountry: (value: any) => void;
  contactFirstname: string;
  setContactFirstname: (value: string) => void;
  contactLastname: string;
  setContactLastname: (value: string) => void;
  contactEmail: string;
  setContactEmail: (value: string) => void;
  companyItExpertsFrom: number | undefined;
  setCompanyItExpertsFrom: (value: number | undefined) => void;
  companyItExpertsTo: number | undefined;
  setCompanyItExpertsTo: (value: number | undefined) => void;
  //syncWithUserContext: () => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export function MyContextProvider({ children }: { children: ReactNode }) {
  const { user, setUser, syncUserSelections } = useUser();

  const [aufwandOne, setAufwandOne] = useState<string>('');
  const [aufwandTwo, setAufwandTwo] = useState<string>('');
  const [aufwandThree, setAufwandThree] = useState<string>('');
  const [aufwandFour, setAufwandFour] = useState<string[]>([]);
  const [aufwandFive, setAufwandFive] = useState<string[]>([]);
  const [aufwandSix, setAufwandSix] = useState<string>('');
  const [aufwandSeven, setAufwandSeven] = useState<string[]>([]);
  const [aufwandEight, setAufwandEight] = useState<string>('');
  const [aufwandNine, setAufwandNine] = useState<string>('');
  const [aufwandTen, setAufwandTen] = useState<string>('');
  const [aufwandEleven, setAufwandEleven] = useState<string>('');
  const [aufwandTwelve, setAufwandTwelve] = useState<string>('');
  const [aufwandThirteen, setAufwandThirteen] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [industrySector, setIndustrySector] = useState<string[]>([]);
  const [companyType, setCompanyType] = useState<string>('');
  const [companyLocation, setCompanyLocation] = useState<string>('');
  const [companyZipcode, setCompanyZipcode] = useState<number>();
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [contactFirstname, setContactFirstname] = useState<string>('');
  const [contactLastname, setContactLastname] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [companyItExpertsFrom, setCompanyItExpertsFrom] = useState<number>();
  const [companyItExpertsTo, setCompanyItExpertsTo] = useState<number>();
  const [dataConnectionsFrom, setDataConnectionsFrom] = useState<number>();
  const [dataConnectionsTo, setDataConnectionsTo] = useState<number>();
  const [incomingDataFrom, setIncomingDataFrom] = useState<number>();
  const [incomingDataTo, setIncomingDataTo] = useState<number>();
  const [userAmountFrom, setUserAmountFrom] = useState<number>();
  const [userAmountTo, setUserAmountTo] = useState<number>();
  const [cpu, setCpu] = useState<number>();
  const [ram, setRam] = useState<number>();
  const [bandwidth, setBandwidth] = useState<number>();

  /* useEffect(() => {
    if (user) {
      setCompanyName(user.companyName);
      setCompanyType(user.companyType);
      setCompanyLocation(user.companyLocation);
      setCompanyZipcode(parseInt(user.companyZipcode));
      setContactFirstname(user.contactFirstname);
      setContactLastname(user.contactLastname);
      setContactEmail(user.email);
      setIndustrySector(user.industrySector || []);
      setSelectedCountry(user.selectedCountry);
      setAufwandOne(user.aufwandOne || '');
      setAufwandTwo(user.aufwandTwo || '');
      setCompanyItExpertsFrom(user.companyItExpertsFrom);
      setCompanyItExpertsTo(user.companyItExpertsTo);
      setAufwandThree(user.aufwandThree || '');
      setAufwandFour(user.aufwandFour || []);
      setAufwandFive(user.aufwandFive || []);
      setAufwandSix(user.aufwandSix || '');
      setAufwandSeven(user.aufwandSeven || []);
      setAufwandEight(user.aufwandEight || '');
      setDataConnectionsFrom(user.dataConnectionsFrom);
      setDataConnectionsTo(user.dataConnectionsTo);
      setAufwandNine(user.aufwandNine || '');
      setIncomingDataFrom(user.incomingDataFrom);
      setIncomingDataTo(user.incomingDataTo);
      setAufwandTen(user.aufwandTen || '');
      setUserAmountFrom(user.userAmountFrom);
      setUserAmountTo(user.userAmountTo);
    }
  }, [user]); */

  /* const syncWithUserContext = () => {
    if (user) {
      setUser({
        ...user,
        companyName,
        companyType,
        companyLocation,
        companyZipcode: companyZipcode,
        contactFirstname: contactFirstname,
        contactLastname: contactLastname,
        email: contactEmail,
        industrySector,
        selectedCountry,
        aufwandOne,
        aufwandTwo,
        companyItExpertsFrom,
        companyItExpertsTo,
        aufwandThree,
        aufwandFour,
        aufwandFive,
        aufwandSix,
        aufwandSeven,
        aufwandEight,
        dataConnectionsFrom,
        dataConnectionsTo,
        aufwandNine,
        incomingDataFrom,
        incomingDataTo,
        aufwandTen,
        userAmountFrom,
        userAmountTo,
      });
    }
  }; */

  const handleSetAufwandOne = (value: string) => {
    setAufwandOne(value);
    if (user) {
      syncUserSelections({ ...user, aufwandOne: value });
    }
  };

  const handleSetAufwandTwo = (value: string) => {
    setAufwandTwo(value);
    if (user) {
      syncUserSelections({ ...user, aufwandTwo: value });
    }
  };

  const handleSetAufwandThree = (value: string) => {
    setAufwandThree(value);
    if (user) {
      syncUserSelections({ ...user, aufwandThree: value });
    }
  };

  const handleSetAufwandFour = (values: string[]) => {
    setAufwandFour(values);
    if (user) {
      syncUserSelections({ ...user, aufwandFour: values });
    }
  };

  const handleSetAufwandFive = (values: string[]) => {
    setAufwandFive(values);
    if (user) {
      syncUserSelections({ ...user, aufwandFive: values });
    }
  };

  const handleSetAufwandSix = (value: string) => {
    setAufwandSix(value);
    if (user) {
      syncUserSelections({ ...user, aufwandSix: value });
    }
  };

  const handleSetAufwandSeven = (values: string[]) => {
    setAufwandSeven(values);
    if (user) {
      syncUserSelections({ ...user, aufwandSeven: values });
    }
  };

  const handleSetAufwandEight = (value: string) => {
    setAufwandEight(value);
    if (user) {
      syncUserSelections({ ...user, aufwandEight: value });
    }
  };

  const handleSetAufwandNine = (value: string) => {
    setAufwandNine(value);
    if (user) {
      syncUserSelections({ ...user, aufwandNine: value });
    }
  };

  const handleSetAufwandTen = (value: string) => {
    setAufwandTen(value);
    if (user) {
      syncUserSelections({ ...user, aufwandTen: value });
    }
  };

  const handleSetAufwandEleven = (value: string) => {
    setAufwandEleven(value);
    if (user) {
      syncUserSelections({ ...user, aufwandEleven: value });
    }
  };

  const handleSetAufwandTwelve = (value: string) => {
    setAufwandTwelve(value);
    if (user) {
      syncUserSelections({ ...user, aufwandTwelve: value });
    }
  };

  const handleSetAufwandThirteen = (value: string) => {
    setAufwandThirteen(value);
    if (user) {
      syncUserSelections({ ...user, aufwandThirteen: value });
    }
  };

  return (
    <MyContext.Provider
      value={{
        aufwandOne,
        setAufwandOne: handleSetAufwandOne,
        aufwandTwo,
        setAufwandTwo: handleSetAufwandTwo,
        aufwandThree,
        setAufwandThree: handleSetAufwandThree,
        aufwandFour,
        setAufwandFour: handleSetAufwandFour,
        aufwandFive,
        setAufwandFive: handleSetAufwandFive,
        aufwandSix,
        setAufwandSix: handleSetAufwandSix,
        aufwandSeven,
        setAufwandSeven: handleSetAufwandSeven,
        aufwandEight,
        setAufwandEight: handleSetAufwandEight,
        aufwandNine,
        setAufwandNine: handleSetAufwandNine,
        aufwandTen,
        setAufwandTen: handleSetAufwandTen,
        aufwandEleven,
        setAufwandEleven: handleSetAufwandEleven,
        aufwandTwelve,
        setAufwandTwelve: handleSetAufwandTwelve,
        aufwandThirteen,
        setAufwandThirteen: handleSetAufwandThirteen,
        //syncWithUserContext,
        companyName,
        setCompanyName,
        industrySector,
        setIndustrySector,
        companyType,
        setCompanyType,
        companyLocation,
        setCompanyLocation,
        companyZipcode,
        setCompanyZipcode,
        selectedCountry,
        setSelectedCountry,
        contactFirstname,
        setContactFirstname,
        contactLastname,
        setContactLastname,
        contactEmail,
        setContactEmail,
        companyItExpertsFrom,
        setCompanyItExpertsFrom,
        companyItExpertsTo,
        setCompanyItExpertsTo,
        dataConnectionsFrom,
        setDataConnectionsFrom,
        dataConnectionsTo,
        setDataConnectionsTo,
        incomingDataFrom,
        setIncomingDataFrom,
        incomingDataTo,
        setIncomingDataTo,
        userAmountFrom,
        setUserAmountFrom,
        userAmountTo,
        setUserAmountTo,
        cpu,
        setCpu,
        ram,
        setRam,
        bandwidth,
        setBandwidth,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export function useMyContext() {
  const context = useContext(MyContext);
  if (context === undefined) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
}
