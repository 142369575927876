import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import { Menu } from '@mui/material';

export default function CheckBoxThirteenEn() {
  const { aufwandThirteen, setAufwandThirteen } = useMyContext();
  const { bandwidth, setBandwidth } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandThirteen);

  const handleUpdate = async (value: any, bandwidth: number) => {
    try {
      const updates = {
        aufwandTwelve: value,
        bandwidth: bandwidth,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandThirteen(value); // Update local context/state immediately
    let bandwidth = 0;

    switch (value) {
      case '10':
        bandwidth = 10;
        break;
      case '11':
        bandwidth = 11;
        break;
      default:
        bandwidth = 10;
    }

    setBandwidth(bandwidth);
    handleUpdate(value, bandwidth); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandThirteen) {
      setAufwandThirteen(selectedValue);
      setSelectedValue(aufwandThirteen);
      setBandwidth(10);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Bandwidth
        </FormLabel>
        <Tooltip
          title='Do you need to transfer or synchronize large amounts of data regularly?'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Choose an element</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Choose an element'
          onChange={handleChange}
        >
          <MenuItem value={'10'}>Low bandwidth required</MenuItem>
          <MenuItem value={'11'}>High bandwidth required</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
